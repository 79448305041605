import React from "react"

import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
)

export default Layout